import { FC, SyntheticEvent } from 'react'
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { CbGameWinner } from '../CbGameWinner'
import { GameCard, Reward, gameDisplay, GameType } from '../../types/Games'
import { ICON_BY_NAME } from '../Icons/Icon'
import Coins from '../../assets/Coins'
import { CbTimeFromNow } from '../CbTimeFromNow'
import { formatNumber, getOrdinal } from '../../helpers'
import { CbButton } from '../Buttons/CbButton'
import RaffleTicket from 'ui/assets/RaffleTicket'

export interface CbGameCardProps extends Partial<Omit<BoxProps, 'id'>> {
  id: number
  name: string
  coverImageUrl?: string
  endDateTime?: Date
  prizeAmount: number
  gameType: GameCard['gameType']
  hasGameButton?: boolean
  hasGameClick?: boolean
  isCompleted?: boolean
  isCurrentUser?: boolean
  isUpcoming?: boolean
  metric: string
  goal: number
  hasPlace?: boolean
  place?: number
  prizeType: Reward['type']
  rewards: Reward[]
  startDateTime?: Date
  totalScore: number
  unit: string
  userName?: string
  userImageUrl?: string
  onGameClick?: (id: number) => void
  onWinningsClick?: (event: SyntheticEvent) => void
  numberOfWinners?: number
}

export const CbGameCardOld: FC<CbGameCardProps> = ({
  id,
  name,
  coverImageUrl,
  endDateTime,
  prizeAmount,
  gameType,
  hasGameButton = true,
  hasGameClick = true,
  isCompleted,
  isCurrentUser,
  isUpcoming,
  metric,
  goal,
  hasPlace = true,
  place,
  prizeType,
  rewards,
  startDateTime,
  totalScore,
  unit,
  userName = '',
  userImageUrl,
  onGameClick,
  onWinningsClick,
  numberOfWinners,
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGameClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    hasGameClick && onGameClick && onGameClick(id)
  }

  const handleWinningsClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    onWinningsClick && onWinningsClick(event)
  }

  const renderWinConditionText = () => {
    switch (gameType) {
      case GameType.Tournament:
        return `Top ${numberOfWinners}`
      case GameType.Race:
        return `First ${numberOfWinners}`
      default:
        return `Unlimited`
    }
  }

  const hasWinners =
    gameType === GameType.Race || gameType === GameType.Tournament

  const renderGameGoal = () => {
    if (gameType === GameType.Tournament) {
      return (
        <>
          <Text
            textAlign={'center'}
            color="white"
            fontSize={{ base: 'sm', md: 'md' }}
            maxWidth={'100%'}
            flexWrap={'wrap'}
            fontFamily={'codenext'}
          >{`${metric}`}</Text>
        </>
      )
    }

    return (
      <>
        <Text
          color="white"
          fontSize="xl"
          isTruncated
          flexWrap={'wrap'}
          fontFamily={'codenext'}
        >{`${formatNumber(goal)}`}</Text>
        <Text
          textAlign={'center'}
          color="white"
          fontSize={'sm'}
          maxWidth={'100%'}
          flexWrap={'wrap'}
          fontFamily={'codenext'}
        >{`${metric}`}</Text>
      </>
    )
  }

  return (
    <Box
      cursor={hasGameClick ? 'pointer' : undefined}
      borderRadius={24}
      boxShadow="0px 4px 21px 3px rgba(0, 0, 0, 0.09)"
      display={isMobile ? 'block' : 'flex'}
      overflow="hidden"
      onClick={handleGameClick}
      transition="transform 0.35s ease"
      transformOrigin="center"
      _hover={hasGameClick ? { transform: 'scale(1.0125)' } : undefined}
      {...props}
    >
      <Box
        background={
          isCompleted
            ? gameDisplay[gameType].completedBgColor
            : gameDisplay[gameType].bgColor
        }
        position="relative"
        width={isMobile ? '100%' : '40%'}
      >
        <Box height={isMobile ? '310px' : '100%'}>
          {isCompleted ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
            >
              {userName ? (
                <CbGameWinner
                  amount={prizeAmount}
                  imageUrl={userImageUrl}
                  isCurrentUser={isCurrentUser}
                  name={userName}
                  prizeType={prizeType}
                  onClick={handleWinningsClick}
                />
              ) : (
                <Text color="text.primaryWhite" fontFamily="codenext">
                  {'No Winner'}
                </Text>
              )}
            </Flex>
          ) : (
            <Box
              backgroundImage={`url("${
                coverImageUrl || gameDisplay[gameType].image
              }")`}
              backgroundPosition="center"
              backgroundSize="cover"
              height="100%"
              width="100%"
              {...props}
            />
          )}
        </Box>
      </Box>
      <Box
        backgroundColor="luigisPants.600"
        border="1px solid"
        borderTop={isMobile ? 'none' : '1px solid'}
        borderLeft={isMobile ? '1px solid' : 'none'}
        borderColor="luigisPants.600"
        borderTopRightRadius={isMobile ? 0 : 24}
        borderBottomLeftRadius={isMobile ? 24 : 0}
        borderBottomRightRadius={24}
        display={isMobile ? 'block' : 'flex'}
        flexDirection="column"
        justifyContent="space-between"
        minHeight={isMobile ? 'auto' : '360px'}
        paddingX={isMobile ? 5 : 12}
        paddingY={5}
        width={isMobile ? '100%' : '47%'}
        flexGrow={1}
      >
        <Box>
          <Text
            color="chonky.400"
            fontWeight="bold"
            letterSpacing="wider"
            marginBottom={isMobile ? 2 : 0}
            textTransform="uppercase"
          >
            {isUpcoming ? 'Upcoming ' : ''}
            {gameDisplay[gameType].title}
          </Text>

          <Text
            color="white"
            fontFamily="codenext"
            noOfLines={2}
            fontSize={isMobile ? 'xl' : '2xl'}
          >
            {name}
          </Text>
        </Box>
        <Box>
          <Flex
            marginTop={isMobile ? 5 : 10}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            {hasPlace && (
              <Flex
                marginBottom={{ base: 4, md: 'auto' }}
                border="1px solid"
                direction="column"
                alignItems="center"
                justifyContent={'space-between'}
                boxShadow="0 0 5px 5px #44D0EF"
                borderRadius={16}
                marginRight={4}
                padding={4}
                textAlign="center"
                width="100%"
              >
                <Text
                  color="cloud.500"
                  fontSize={{ base: 'xs' }}
                  marginBottom={{ base: 2 }}
                >
                  {'Your Position'}
                </Text>
                <Text
                  color="white"
                  fontSize={{ base: 'lg', md: 'xl' }}
                  fontFamily="codenext"
                  lineHeight="shorter"
                >
                  {place ? getOrdinal(place) : '-'}
                </Text>
              </Flex>
            )}
            <Flex
              marginBottom={{ base: 4, md: 'auto' }}
              border="1px solid"
              direction="column"
              alignItems="center"
              justifyContent={'space-between'}
              borderColor="cloud.800"
              borderRadius={16}
              padding={4}
              textAlign="center"
              width="100%"
            >
              <Text
                color="cloud.500"
                fontSize={{ base: 'xs' }}
                marginBottom={{ base: 2 }}
                noOfLines={1}
              >
                Your {metric}
              </Text>
              <Text
                color="white"
                fontSize={{ base: 'lg', md: 'xl' }}
                fontFamily="codenext"
                lineHeight="shorter"
              >
                {totalScore || '-'}
              </Text>
            </Flex>
            {!isCompleted && (
              <Flex
                marginBottom={{ base: 4, md: 'auto' }}
                border="1px solid"
                direction="column"
                alignItems="center"
                justifyContent={'space-between'}
                borderColor="cloud.800"
                borderRadius={16}
                marginLeft={{ md: 4 }}
                padding={4}
                textAlign="center"
                width="100%"
              >
                <Text
                  color="cloud.500"
                  fontSize={{ base: 'xs' }}
                  marginBottom={{ base: 2 }}
                >
                  {'1st Place'}
                </Text>
                <Text
                  color="white"
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontFamily="codenext"
                  lineHeight="shorter"
                  noOfLines={{ base: 2, md: 1 }}
                >
                  {userName || '-'}
                </Text>
              </Flex>
            )}
          </Flex>
          <Text
            textAlign={'center'}
            marginTop={6}
            marginBottom={4}
            fontWeight="bold"
            color="cloud.500"
            fontSize="xs"
          >
            {'HOW TO PLAY'}
          </Text>
          <Divider marginBottom={8} />
          <Flex paddingX={8} justifyContent="space-around">
            {hasWinners && (
              <Flex direction="column" alignItems={'center'} maxWidth="33%">
                <Text fontFamily={'codenext'} fontSize={'xs'} color="cloud.500">
                  {'Winners'}
                </Text>
                <Text
                  color="white"
                  fontSize={{ md: 'lg' }}
                  isTruncated
                  maxWidth="100%"
                  fontFamily={'codenext'}
                >
                  {renderWinConditionText()}
                </Text>
              </Flex>
            )}
            <Flex direction="column" alignItems={'center'} maxWidth="33%">
              <Text fontFamily={'codenext'} fontSize={'xs'} color="cloud.500">
                {'Player Target'}
              </Text>
              {renderGameGoal()}
            </Flex>
            {!isCompleted && (
              <Flex direction="column" alignItems={'center'} maxWidth="33%">
                <Text fontFamily={'codenext'} fontSize={'xs'} color="cloud.500">
                  {'Time Left'}
                </Text>
                <CbTimeFromNow
                  endDateTime={endDateTime}
                  isUpcoming={isUpcoming}
                  startDateTime={startDateTime}
                  color="white"
                  fontSize={{ md: 'lg' }}
                  isTruncated
                  maxWidth="100%"
                  fontFamily={'codenext'}
                />
              </Flex>
            )}
          </Flex>
          <Flex
            direction="column"
            alignItems={'center'}
            backgroundColor={'#12275A'}
            padding={1}
            borderRadius={16}
            marginTop={8}
          >
            <Text
              textAlign={'center'}
              fontWeight="bold"
              color="cloud.500"
              fontSize="xs"
              marginTop={2}
            >
              {'PRIZES'}
            </Text>
            <Flex
              width="100%"
              justifyContent={
                rewards.length > 3 ? 'space-between' : 'space-evenly'
              }
              marginY={4}
              flexWrap={'wrap'}
              paddingX={2}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              {rewards.map((reward, i) => {
                if (reward.type === 'token_jackpot') {
                  return (
                    <Flex alignItems={'center'} key={i}>
                      <Flex
                        padding={2}
                        borderRadius={100}
                        height={'35px'}
                        width={'35px'}
                        fontSize="sm"
                        backgroundColor="luigisPants.600"
                        justifyContent={'center'}
                        alignItems={'center'}
                        color="white"
                        fontFamily={'codenext'}
                        marginRight={2}
                      >
                        {getOrdinal(i + 1)}
                      </Flex>
                      <RaffleTicket width={9} height="auto" marginRight={2} />
                      <Text color="white" fontWeight={'bold'}>
                        {`x ${formatNumber(
                          Number(reward.value.common || reward.value),
                        )}`}
                      </Text>
                    </Flex>
                  )
                } else {
                  return (
                    <Flex alignItems={'center'} marginBottom={2} key={i}>
                      <Flex
                        padding={2}
                        borderRadius={100}
                        height={'35px'}
                        width={'35px'}
                        fontSize="sm"
                        backgroundColor="luigisPants.600"
                        justifyContent={'center'}
                        alignItems={'center'}
                        color="white"
                        fontFamily={'codenext'}
                        marginRight={4}
                      >
                        {getOrdinal(i + 1)}
                      </Flex>
                      <Coins width={9} height="auto" marginRight={2} />
                      <Text color="white" fontWeight={'bold'}>
                        {formatNumber(Number(reward.value))}
                      </Text>
                    </Flex>
                  )
                }
              })}
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            marginTop={isMobile ? 6 : 8}
          >
            {hasGameButton && (
              <CbButton variant="ghost-on-dark" onClick={handleGameClick}>
                {'See Game'}
                <ICON_BY_NAME.arrowright height="14px" marginLeft={2} />
              </CbButton>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
